import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function EnvironmentalFactors({ readOnly = false }: Props) {
  const methods = useFormContext();
  const getValue = methods.getValues;
  const { isVisible, visibility, updateHandler } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  // force MOVE fields hidden when hospital is not in LIS & MOVE
  // field 114 is given by the back-end to indicate if the hospital is in both LIS & MOVE
  // if not, hide the MOVE fields by removing all visibility rules for the MOVE fields
  // and replace them with a single rule that hides them
  // 
  // this implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const isLisMoveHospital = getValue('114') === true;

  const field_76 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 76, description: 'Te water', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_77 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 77, description: 'Gladheid', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_78 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 78, description: 'Weer', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_79 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 79, description: 'Infra', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_80 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 80, description: 'Product/materiaal', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));


  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Omgevingsfactoren</legend>
      <FormRow fieldId="76" isActive={visibility[76]} fieldRules={field_76.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_76.current.description}</span>
        <Controller
          control={methods.control}
          name="76"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_76.current.options} valueRules={field_76.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="77" isActive={visibility[77]} fieldRules={field_77.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_77.current.description}</span>
        <Controller
          control={methods.control}
          name="77"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_77.current.options} valueRules={field_77.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="78" isActive={visibility[78]} fieldRules={field_78.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_78.current.description}</span>
        <Controller
          control={methods.control}
          name="78"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_78.current.options} valueRules={field_78.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="79" isActive={visibility[79]} fieldRules={field_79.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_79.current.description}</span>
        <Controller
          control={methods.control}
          name="79"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_79.current.options} valueRules={field_79.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="80" isActive={visibility[80]} fieldRules={field_80.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_80.current.description}</span>
        <Controller
          control={methods.control}
          name="80"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_80.current.options} valueRules={field_80.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}