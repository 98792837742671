import { AxiosApi } from '@src/index';
import { AxiosResponse } from 'axios';
import { FormField, FormFieldRule } from '@src/support/components/form/models';
const responseBody = (response: AxiosResponse): any => response.data
const apiBaseUrl = '/api';

const requests = {
  get: (url: string, params?: URLSearchParams) => AxiosApi.get(url, { params }).then(responseBody),
  post: (url: string, body: object) => AxiosApi.post(url, body).then(responseBody),
  put: (url: string, body: object) => AxiosApi.put(url, body).then(responseBody),
  delete: (url: string) => AxiosApi.delete(url).then(responseBody),
}

function castFormFields(formFields: FormField[]) {
  return formFields.map(ff => {
    ff.listId = (!ff.listId) ? ff.listId : Number(ff.listId);

    return ff;
  })
}

function castFormFieldRules (formFieldRules: FormFieldRule[]) {
  return formFieldRules.map(ffr => {
    ffr.masterValueInt = (!ffr.masterValueInt) ? ffr.masterValueInt : Number(ffr.masterValueInt);
    return ffr;
  })
}

const cacheAPI = {
  version: () => requests.get(`${apiBaseUrl}/dataset/version`),
  lisRegistrationFilters: () => requests.get(`${apiBaseUrl}/lis/registrations/filters`),
  lisUploadsFilters: () => requests.get(`${apiBaseUrl}/lis/uploads/filters`),
  moveRegistrationFilters: () => requests.get(`${apiBaseUrl}/move/registrations/filters`),
  moveUploadsFilters: () => requests.get(`${apiBaseUrl}/move/uploads/filters`),
  lisFormFields: () => requests.get(`${apiBaseUrl}/dataset/lis/form/fields`).then(castFormFields),
  lisFormValues: () => requests.get(`${apiBaseUrl}/dataset/lis/form/values`),
  lisFormFieldRules: () => requests.get(`${apiBaseUrl}/dataset/lis/form/fieldrules`).then(castFormFieldRules),
  lisFormValueRules: () => requests.get(`${apiBaseUrl}/dataset/lis/form/valuerules`),  
  formFields: () => requests.get(`${apiBaseUrl}/dataset/move/form/fields`).then(castFormFields),
  formValues: () => requests.get(`${apiBaseUrl}/dataset/move/form/values`),
  formFieldRules: () => requests.get(`${apiBaseUrl}/dataset/move/form/fieldrules`).then(castFormFieldRules),
  formValueRules: () => requests.get(`${apiBaseUrl}/dataset/move/form/valuerules`),
  organizationsFilters: () => requests.get(`${apiBaseUrl}/organizations/filters`),
}

export default cacheAPI