import { useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';
import DatePicker from '@src/support/components/datePicker';

type Props = {
  readOnly?: boolean,
}

export default function Treatment({ readOnly = false }: Props) {
  const methods = useFormContext();
  const { visibility, updateHandler } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  const field_30 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 30,
    description: 'Doorverwijzing',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  /*
  const field_31 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 31,
    description: 'Specialisme hoofdbehandelaar',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));


  const field_32 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 32,
    description: 'Tijdstip aanvang behandeling',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));  
  */

  const field_33 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 33,
    description: 'Datum ontslag ziekenhuis',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  /*
  const field_34 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 34,
    description: 'Tijdstip ontslag',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));    
  */

  const field_35 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 35,
    description: 'Overleden tijdens opname',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Behandeling</legend>
      <FormRow fieldId="30" isActive={true} fieldRules={field_30.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_30.current.description}</span>
        <Controller
          control={methods.control}
          name="30"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error} onChange={onChange} options={field_30.current.options} valueRules={field_30.current.valueRules} />
          )}
        />;
      </FormRow>

      {/*
      <FormRow fieldId="31" isActive={true}>
        <span>{field_31.current.description}</span>
        <span>{getValueLabel(record?.[31], field_31.current)}</span>
      </FormRow>
 

      <FormRow fieldId="32" isActive={true}>
        <span>{field_32.current.description}</span>
        <span>{record?.[32]}</span>
      </FormRow>
           */}

      <FormRow fieldId="33" isActive={visibility[33]} fieldRules={field_33.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_33.current.description}</span>
        <Controller
          control={methods.control}
          name="33"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <DatePicker name={name} selected={value ? new Date(value) : null} error={error} onChange={onChange} />
          )}
        />;
      </FormRow>

      {/*
      <FormRow fieldId="34" isActive={true}>
        <span>{field_34.current.description}</span>
        <span>{record?.[34]}</span>
      </FormRow>
      */}

      <FormRow fieldId="35" isActive={visibility[35]} fieldRules={field_35.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_35.current.description}</span>
        <Controller
          control={methods.control}
          name="35"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error} onChange={onChange} options={field_35.current.options} valueRules={field_35.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}