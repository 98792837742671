import { FormField, FormFieldSelect, FormFieldRule, FormValue, FormValueRule } from '@src/support/components/form/models';

interface FormFieldProps {
  fieldId: number | string
  description: string
  formFields: FormField[]
  fieldRules: FormFieldRule[]
  forceHidden?: boolean
}

interface FormFieldSelectProps extends FormFieldProps {
  values: FormValue[]
  valueRules: FormValueRule[]
}

export function getFormField({ fieldId, description, formFields, fieldRules, forceHidden }: FormFieldProps) {
  const field = formFields.find(field => field.id === fieldId) ?? {} as FormField;
  let fieldRulesFiltered;

  // forceHidden: true
  // ----------------
  // Hides a field by overriding its visibility rules with a single rule/condition that will never be true.
  // This is used to hide MOVE fields in the (combined) LIS form when the hospital is NOT in both LIS & MOVE.
  // In this case, all other visibility rules can be ignored as the field should always be hidden. This implementation 
  // helps to speed-up the rendering by avoiding unnecessary visibility rules checks.
  //
  // Why not just hide the field in the template?
  // --------------------------------------------
  // The parent fieldsets are dynamically hidden based on the visibility rules of the fields inside it. Just hiding the field in the template
  // will break this behavior.
  // 
  if (forceHidden === true) {
    fieldRulesFiltered = [{ masterFieldId: -1, masterValueInt: -1, fieldId: field.id!, groupId: null, groupOperator: null}];
  } else {
    fieldRulesFiltered = fieldRules.filter(condition => condition.fieldId === Number(fieldId))
  }

  return {
    ...field,
    ...fieldRulesFiltered.length ? { fieldRules: fieldRulesFiltered } : {},
    description: description
  } as FormField;
}

export function getFormSelect({ fieldId, description, formFields, values, fieldRules, valueRules, forceHidden }: FormFieldSelectProps) {
  const field = getFormField({ fieldId, description, formFields, fieldRules, forceHidden });
  const optionValues = values.filter(value => value.listId === field?.listId) ?? [];
  const valueRulesFiltered = valueRules.filter(condition => condition.fieldId === fieldId);

  return {
    ...field,
    options: optionValues,
    valueRules: valueRulesFiltered,
  } as FormFieldSelect
}

export function getValueLabel(value: number | undefined, selectOptions: FormFieldSelect) {
  if (!value || value === undefined) return '';
  const label = selectOptions.options.find(x => x.id === Number(value))?.label || '';
  // show raw value if the value is not one of the selectOptions
  if (label === '') return value.toString();
  // Remove number and dot
  let modifiedLabel = label.replace(/^\d+\.\s/, '');
  // Capitalize the first letter of the modified string
  modifiedLabel = modifiedLabel.charAt(0).toUpperCase() + modifiedLabel.slice(1);
  return modifiedLabel;
}