import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function RoadwayFactors({ readOnly = false }: Props) {
  const methods = useFormContext();
  const getValue = methods.getValues;
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  // force MOVE fields hidden when hospital is not in LIS & MOVE
  // field 114 is given by the back-end to indicate if the hospital is in both LIS & MOVE
  // if not, hide the MOVE fields by removing all visibility rules for the MOVE fields
  // and replace them with a single rule that hides them
  // 
  // this implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const isLisMoveHospital = getValue('114') === true;

  const field_72 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 72, description: 'Wegsituatie', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_73 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 73, description: 'Locatie op de weg', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_74 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 74, description: 'Manoeuvre', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_75 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 75, description: 'File', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Wegfactoren</legend>
      <FormRow fieldId="72" isActive={visibility[72]} fieldRules={field_72.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_72.current.description}</span>
        <Controller
          control={methods.control}
          name="72"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_72.current.options} valueRules={field_72.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="73" isActive={visibility[73]} fieldRules={field_73.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_73.current.description}</span>
        <Controller
          control={methods.control}
          name="73"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_73.current.options} valueRules={field_73.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="74" isActive={visibility[74]} fieldRules={field_74.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_74.current.description}</span>
        <Controller
          control={methods.control}
          name="74"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_74.current.options} valueRules={field_74.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="75" isActive={visibility[75]} fieldRules={field_75.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_75.current.description}</span>
        <Controller
          control={methods.control}
          name="75"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_75.current.options} valueRules={field_75.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}