import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import lisRecordAPI from '@features/lisRecord/api/api';
import { LisRecordResponse } from '@features/lisRecord/models/models';

// support
import KeyValue from '@support/models/keyValue'

export interface LisRecordState {
    status: 'idle' | 'loading' | 'failed',
    record: KeyValue | undefined
}

const initialState: LisRecordState = {
    status: 'idle',
    record: undefined
};

export const STATUS_FIELD_ID = 1;

export const STATUS_DUPLICATE = '150';
export const STATUS_NEW = '300';
export const STATUS_DECLINED = '350';
export const STATUS_ACCEPTED = '400';

/**
 * Fetches the record
 */
export const fetchLisRecordAsync = createAsyncThunk<LisRecordResponse, string, { state: RootState }>(
    'lisRecord/fetchLisRecordAsync',
    async (recordId, thunkAPI,) => {
        try {
            return await lisRecordAPI.record(recordId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

/**
 * Save the record
 */
export const saveRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
    'lisRecord/saveRecordAsync',
    async (recordObj, thunkAPI,) => {
        try {
            return await lisRecordAPI.save(recordObj?.id, recordObj);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

/**
 * Delete the record
 */
export const deleteRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
    'lisRecordAPI/deleteRecordAsync',
    async (recordObj, thunkAPI,) => {
        try {
            return await lisRecordAPI.delete(recordObj?.id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data, status: error.response.status })
        }
    }
)

/**
 * Approve the record
 */
export const approveRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
    'lisRecord/approveRecordAsync',
    async (recordObj, thunkAPI,) => {
        const newRecord = { ...recordObj, [STATUS_FIELD_ID]: STATUS_ACCEPTED };
        try {
            return await lisRecordAPI.save(newRecord?.id, newRecord);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

/**
 * Discuss the record
 */
export const rejectRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
    'lisRecord/discussRecordAsync',
    async (recordObj, thunkAPI,) => {
        const newRecord = { ...recordObj, [STATUS_FIELD_ID]: STATUS_DECLINED };
        try {
            return await lisRecordAPI.save(newRecord?.id, newRecord);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

/**
 * Delete the duplicate record
 */
export const discardDuplicateRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
    'lisRecord/discardDuplicateRecordAsync',
    async (recordObj, thunkAPI,) => {
        try {
            return await lisRecordAPI.discardDuplicate(recordObj?.id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data, status: error.response.status });
        }
    }
)

/**
 * Stage the duplicate record
 */
export const stageDuplicateRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
    'lisRecord/stageDuplicateRecordAsync',
    async (recordObj, thunkAPI,) => {
        try {
            return await lisRecordAPI.stageDuplicate(recordObj?.id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data, status: error.response.status });
        }
    }
)

/**
 * Lock the record
 */
export const lockCurrentRecordAsync = createAsyncThunk<any, void, { state: RootState }>(
    'lisRecord/lockRecordAsync',
    async (_, thunkAPI,) => {
        const record = thunkAPI.getState().lisRecord.record;
        try {
            return await lisRecordAPI.lock(record?.id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

/**
 * Lock the record
 */
export const unlockCurrentRecordAsync = createAsyncThunk<any, void, { state: RootState }>(
    'lisRecord/unlockRecordAsync',
    async (_, thunkAPI,) => {
        const record = thunkAPI.getState().lisRecord.record;
        try {
            return await lisRecordAPI.unlock(record?.id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.response.data })
        }
    }
)

export const lisRecordSlice = createSlice({
    name: 'lisRecord',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setRecord: (state, action: PayloadAction<object>) => {
            state.record = action.payload;
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            // --------------------------------------------------------------------------
            // fetch
            .addCase(fetchLisRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLisRecordAsync.fulfilled, (state, action) => {
                // temp remove field 59

                state.record = { ...action.payload, '59': null };
                state.record.readOnly = state.record.lockedByOther || state.record[STATUS_FIELD_ID] === STATUS_DUPLICATE;
                state.record.touched = state.record.gebruiker !== undefined && state.record.gebruiker !== 'Azure Machine Learning' && state.record.gebruiker !== 'Azure Data Factory';
                // create unique key so the record is updated correcty, even when
                // the same record is loaded 2 times in a row
                state.record.uniqueKey = new Date().getTime();
                state.status = 'idle';

            })
            .addCase(fetchLisRecordAsync.rejected, (state) => {
                state.status = 'failed';
            })

            // --------------------------------------------------------------------------
            // save
            .addCase(saveRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(saveRecordAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(saveRecordAsync.rejected, (state) => {
                state.status = 'idle';
            })

            // --------------------------------------------------------------------------
            // delete
            .addCase(deleteRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteRecordAsync.fulfilled, (state) => {
                state.status = 'idle';
            })
            .addCase(deleteRecordAsync.rejected, (state) => {
                state.status = 'idle';
            })

            // --------------------------------------------------------------------------
            // approve
            .addCase(approveRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(approveRecordAsync.fulfilled, (state) => {
                state.status = 'loading'; // set to loading because we move to the next record directly
            })
            .addCase(approveRecordAsync.rejected, (state) => {
                state.status = 'idle';
            })

            // --------------------------------------------------------------------------
            // discuss
            .addCase(rejectRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(rejectRecordAsync.fulfilled, (state) => {
                state.status = 'loading'; // set to loading because we move to the next record directly
            })
            .addCase(rejectRecordAsync.rejected, (state) => {
                state.status = 'idle';
            })

            // --------------------------------------------------------------------------
            // delete duplicate
            .addCase(discardDuplicateRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(discardDuplicateRecordAsync.fulfilled, (state) => {
                state.status = 'loading'; // set to loading because we move to the next record directly
            })
            .addCase(discardDuplicateRecordAsync.rejected, (state) => {
                state.status = 'idle';
            })

            // --------------------------------------------------------------------------
            // stage duplicate
            .addCase(stageDuplicateRecordAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(stageDuplicateRecordAsync.fulfilled, (state) => {

                state.status = 'loading'; // set to loading because we move to the next record directly
            })
            .addCase(stageDuplicateRecordAsync.rejected, (state) => {
                state.status = 'idle';
            })
    },
});

export const { setRecord } = lisRecordSlice.actions;

export default lisRecordSlice.reducer;
