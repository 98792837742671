import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import lisRegistrationAPI from '@features/lisRegistration/api/api';
import { LisRegistrationResponse, LisRegistrationFilter } from '@features/lisRegistration/models/models';

// support
import { ListViewSort } from '@support/components/listView/models';

export interface LisRegistrationState {
  status: 'idle' | 'loading' | 'failed';
  listData: LisRegistrationResponse | undefined,
  recordIds: string[],
  currentPage: number,
  totalPages: number,
  filter: LisRegistrationFilter
}

const initialState: LisRegistrationState = {
  status: 'idle',
  listData: undefined,
  recordIds: [],
  currentPage: 1,
  totalPages: 1,
  filter: {
    statuses: ['300'],
    organizations: [],
    injuryCause: [],
    party: [],
    startDate: '',
    endDate: '',
    query: '',
    page: 1,
    sort: {
      key: '',
      direction: ''
    }
  }
};

/**
 * Converts a `LisRegistrationFilter` object into a `URLSearchParams` object.
 */
function createUrlParamsFromFilter(filter: LisRegistrationFilter, paged: boolean) {
  const params = new URLSearchParams();

  if (paged && filter.page) params.append('page', filter.page.toString())

  if (filter.sort.key !== '') {
    params.append('sortKey', filter.sort.key);
    params.append('sortDirection', filter.sort.direction);
  }

  if (filter.query.length) params.append('query', filter.query);

  filter.statuses.forEach(s => {
    params.append('status', s);
  });

  filter.organizations.forEach(o => {
    params.append('organization', o);
  });

  filter.injuryCause.forEach(o => {
    params.append('injuryCause', o);
  });

  filter.party.forEach(o => {
    params.append('party', o);
  });

  if (filter.startDate) params.append('startDate', filter.startDate.toString());
  if (filter.endDate) params.append('endDate', filter.endDate.toString());

  return params;
}

/**
 * Fetches the listview with the selected filter settings
 */
export const fetchLisRegistrationListAsync = createAsyncThunk<LisRegistrationResponse, void, { state: RootState }>(
  'lisRegistration/fetchLisRegistrationListAsync',
  async (_, thunkAPI,) => {
    const filter = thunkAPI.getState().lisRegistration.filter;
    const params = createUrlParamsFromFilter(filter, true);

    try {
      return await lisRegistrationAPI.list(params);
    } catch (error: any) {
      if (!error.response) throw error
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Fetches the record id's based on list view filter settings
 * These ids will be used on the record detail page for navigating
 * to the previous / next record
 */
export const fetchLisRecordIdsAsync = createAsyncThunk<string[], void, { state: RootState }>(
  'moveRegistration/fetchMoveRecordIdsAsync',
  async (_, thunkAPI,) => {
    const filter = thunkAPI.getState().lisRegistration.filter;
    const params = createUrlParamsFromFilter(filter, false);

    try {
      return await lisRegistrationAPI.ids(params);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const lisRegistrationSlice = createSlice({
  name: 'lisRegistration',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setFilter: (state, action: PayloadAction<LisRegistrationFilter>) => {
      state.filter = action.payload;
    },
    /**
    * Filter values
    */
    setStatus: (state, action: PayloadAction<string[]>) => {
      state.filter.statuses = action.payload;
      state.filter.page = 1;
    },
    setOrganization: (state, action: PayloadAction<string[]>) => {
      state.filter.organizations = action.payload;
      state.filter.page = 1;
    },
    setInjuryCause: (state, action: PayloadAction<string[]>) => {
      state.filter.injuryCause = action.payload;
      state.filter.page = 1;
    },
    setParty: (state, action: PayloadAction<string[]>) => {
      state.filter.party = action.payload;
      state.filter.page = 1;
    },
    setStartDate: (state, action: PayloadAction<string>) => {
      state.filter.startDate = action.payload;
    },
    setEndDate: (state, action: PayloadAction<string>) => {
      state.filter.endDate = action.payload;
      state.filter.page = 1;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filter.page = action.payload;
    },
    setSortKey: (state, action: PayloadAction<ListViewSort>) => {
      state.filter.sort = action.payload;
      state.filter.page = 1;
    },
    setQuery: (state, action: PayloadAction<string>) => {
      if (state.filter.query !== action.payload) {
        state.filter.query = action.payload;
        state.filter.page = 1;
      }
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    removePrevNextRecordId: (state, action: PayloadAction<string>) => {
      state.recordIds = state.recordIds.filter(record => record !== action.payload);
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // --------------------------------------------------------------------------
      // list
      .addCase(fetchLisRegistrationListAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLisRegistrationListAsync.fulfilled, (state, action) => {
        state.listData = action.payload;
        state.currentPage = action.payload.currentPage;
        state.totalPages = action.payload.totalPages;
        state.status = 'idle';
      })
      .addCase(fetchLisRegistrationListAsync.rejected, (state, action) => {
        // do not show failed when request was canceled
        if (action?.error?.message !== 'canceled') {
          state.status = 'failed';
        }
      })
      // --------------------------------------------------------------------------
      // ids
      .addCase(fetchLisRecordIdsAsync.fulfilled, (state, action) => {
        state.recordIds = action.payload
      })
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
export const { setFilter, setStatus, setOrganization, setInjuryCause, setParty, setStartDate, setEndDate, setQuery, setPage, setSortKey, removePrevNextRecordId } = lisRegistrationSlice.actions;

export default lisRegistrationSlice.reducer;
