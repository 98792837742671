import FormRow from '@src/support/components/form/FormRow';
import FormFieldset from '@src/support/components/form/FormFieldset';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';

import { Controller, useFormContext } from 'react-hook-form';
import { useRef } from 'react';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';

import { useAppSelector } from '@src/app/store/hooks';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';

type Props = {
  readOnly?: boolean,
}

export default function PersonCharacteristics({ readOnly = false }: Props) {
  const methods = useFormContext();
  const getValue = methods.getValues;
  const { visibility, updateHandler, isVisible } = useFormVisibility();
  
  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  // force MOVE fields hidden when hospital is not in LIS & MOVE
  // field 114 is given by the back-end to indicate if the hospital is in both LIS & MOVE
  // if not, hide the MOVE fields by removing all visibility rules for the MOVE fields
  // and replace them with a single rule that hides them
  // 
  // this implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const isLisMoveHospital = getValue('114') === true;

  const field_67 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 67, description: 'Airbag', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_68 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 68, description: 'Gordel', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_69 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 69, description: 'Helm', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_70 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 70, description: 'Kinderzitje', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));
  const field_71 = useRef<FormFieldSelect>(getFormSelect({ forceHidden: !isLisMoveHospital, fieldId: 71, description: 'ADAS', formFields: formFields.current, fieldRules: formFieldRules.current, values: formValues.current, valueRules: formValueRules.current }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Beschermmiddelen</legend>
      <FormRow fieldId="67" isActive={visibility[67]} fieldRules={field_67.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_67.current.description}</span>
        <Controller
          control={methods.control}
          name="67"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_67.current.options} valueRules={field_67.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="68" isActive={visibility[68]} fieldRules={field_68.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_68.current.description}</span>
        <Controller
          control={methods.control}
          name="68"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_68.current.options} valueRules={field_68.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="69" isActive={visibility[69]} fieldRules={field_69.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_69.current.description}</span>
        <Controller
          control={methods.control}
          name="69"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_69.current.options} valueRules={field_69.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="70" isActive={visibility[70]} fieldRules={field_70.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_70.current.description}</span>
        <Controller
          control={methods.control}
          name="70"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_70.current.options} valueRules={field_70.current.valueRules} />
          )}
        />;
      </FormRow>
      <FormRow fieldId="71" isActive={visibility[71]} fieldRules={field_71.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_71.current.description}</span>
        <Controller
          control={methods.control}
          name="71"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_71.current.options} valueRules={field_71.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}