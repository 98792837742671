import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'

// features
import MoveRecordAPI from '@features/moveRecord/api/api';
import { MoveRecordResponse } from '@features/moveRecord/models/models';

// support
import KeyValue from '@support/models/keyValue'

export const STATUS_DUPLICATE = '150';
export const STATUS_NEW = '300';
export const STATUS_LINKED_HIDDEN = '250';
export const STATUS_DECLINED = '350';
export const STATUS_ACCEPTED = '400';

export interface MoveRecordState {
  status: 'idle' | 'loading' | 'failed',
  record: KeyValue | undefined
}

const initialState: MoveRecordState = {
  status: 'idle',
  record: undefined
};

/**
 * Fetches the record
 */
export const fetchMoveRecordAsync = createAsyncThunk<MoveRecordResponse, string, { state: RootState }>(
  'moveRecord/fetchMoveRecordAsync',
  async (recordId, thunkAPI,) => {
    try {
      return await MoveRecordAPI.record(recordId);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Save the record
 */
export const saveRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
  'moveRecord/saveRecordAsync',
  async (recordObj, thunkAPI,) => {
    try {
      return await MoveRecordAPI.save(recordObj?.id, recordObj);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Delete the record
 */
export const deleteRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
  'moveRecord/deleteRecordAsync',
  async (recordObj, thunkAPI,) => {
    try {
      return await MoveRecordAPI.delete(recordObj?.id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data, status: error.response.status })
    }
  }
)

/**
 * Approve the record
 */
export const approveRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
  'moveRecord/approveRecordAsync',
  async (recordObj, thunkAPI,) => {
    const newRecord = { ...recordObj, status: '400' };
    try {
      return await MoveRecordAPI.save(newRecord?.id, newRecord);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Discuss the record
 */
export const discussRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
  'moveRecord/discussRecordAsync',
  async (recordObj, thunkAPI,) => {
    const newRecord = { ...recordObj, status: STATUS_DECLINED };
    try {
      return await MoveRecordAPI.save(newRecord?.id, newRecord);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Lock the record
 */
export const lockCurrentRecordAsync = createAsyncThunk<any, void, { state: RootState }>(
  'moveRecord/lockRecordAsync',
  async (_, thunkAPI,) => {
    const record = thunkAPI.getState().moveRecord.record;
    try {
      return await MoveRecordAPI.lock(record?.id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Lock the record
 */
export const unlockCurrentRecordAsync = createAsyncThunk<any, void, { state: RootState }>(
  'moveRecord/unlockRecordAsync',
  async (_, thunkAPI,) => {
    const record = thunkAPI.getState().moveRecord.record;
    try {
      return await MoveRecordAPI.unlock(record?.id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

/**
 * Delete the duplicate record
 */
export const discardDuplicateRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
  'moveRecord/discardDuplicateRecordAsync',
  async (recordObj, thunkAPI,) => {
    try {
      return await MoveRecordAPI.discardDuplicate(recordObj?.id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data, status: error.response.status });
    }
  }
)

/**
* Stage the duplicate record
*/
export const stageDuplicateRecordAsync = createAsyncThunk<any, any, { state: RootState }>(
  'moveRecord/stageDuplicateRecordAsync',
  async (recordObj, thunkAPI,) => {
    try {
      return await MoveRecordAPI.stageDuplicate(recordObj?.id);
    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data, status: error.response.status });
    }
  }
)


export const moveRecordSlice = createSlice({
  name: 'moveRecord',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setRecord: (state, action: PayloadAction<object>) => {
      state.record = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder

      // --------------------------------------------------------------------------
      // fetch
      .addCase(fetchMoveRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchMoveRecordAsync.fulfilled, (state, action) => {
        state.record = action.payload;
        state.record.readOnly = state.record?.lockedByOther || state.record?.status === STATUS_DUPLICATE || state.record.isLinkedToLis;
        state.record.touched = state.record.gebruiker !== undefined && state.record.gebruiker !== 'Azure Machine Learning' && state.record.gebruiker !== 'Azure Data Factory';
        // create unique key so the record is updated correcty, even when
        // the same record is loaded 2 times in a row
        state.record.uniqueKey = new Date().getTime();
        state.status = 'idle';
      })
      .addCase(fetchMoveRecordAsync.rejected, (state) => {
        state.status = 'failed'; // show error in stead of form
      })

      // --------------------------------------------------------------------------
      // delete
      .addCase(deleteRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteRecordAsync.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(deleteRecordAsync.rejected, (state) => {
        state.status = 'idle';
      })

      // --------------------------------------------------------------------------
      // save
      .addCase(saveRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(saveRecordAsync.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(saveRecordAsync.rejected, (state) => {
        state.status = 'idle';
      })

      // --------------------------------------------------------------------------
      // approve
      .addCase(approveRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(approveRecordAsync.fulfilled, (state) => {
        state.status = 'loading'; // set to loading because we move to the next record directly
      })
      .addCase(approveRecordAsync.rejected, (state) => {
        state.status = 'idle';
      })

      // --------------------------------------------------------------------------
      // discuss
      .addCase(discussRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(discussRecordAsync.fulfilled, (state) => {
        state.status = 'loading'; // set to loading because we move to the next record directly
      })
      .addCase(discussRecordAsync.rejected, (state) => {
        state.status = 'idle';
      })

      // --------------------------------------------------------------------------
      // discard duplicate
      .addCase(discardDuplicateRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(discardDuplicateRecordAsync.fulfilled, (state) => {
        state.status = 'loading'; // set to loading because we move to the next record directly
      })
      .addCase(discardDuplicateRecordAsync.rejected, (state) => {
        state.status = 'idle';
      })

      // --------------------------------------------------------------------------
      // stage duplicate
      .addCase(stageDuplicateRecordAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(stageDuplicateRecordAsync.fulfilled, (state) => {
        state.status = 'loading'; // set to loading because we move to the next record directly
      })
      .addCase(stageDuplicateRecordAsync.rejected, (state) => {
        state.status = 'idle';
      })
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.songList.value)`
// export const  { } = moveRecordSlice.actions;

export const { setRecord } = moveRecordSlice.actions;

export default moveRecordSlice.reducer;
