import { useCallback } from 'react';
import { ValidateResult, useFormContext } from 'react-hook-form';
import { AllowedCombinationsInfo, FieldValue, UniquePairsInfo, UniqueValuesInfo } from '../components/form/models';
import KeyValue from '../models/keyValue';

export function useFormValidators() {

  const methods = useFormContext();
  const trigger = methods.trigger;
  const getValues = methods.getValues;
  const setValue = methods.setValue;

  /**
  * Checks if the value is set
  * 
  * @value: the value of field
  */
  const isSet = useCallback((value: string): boolean | ValidateResult => {
    return value !== null && value !== ''
  }, []);

  /**
   * Triggers the validation o all fields in uniquePairs 
   * 
   * @uniquePairs: UniquePairsInfo object
  */
  const triggerRelatedPairs = useCallback((uniquePairs: UniquePairsInfo): void => {
    uniquePairs.pairs.forEach((pair) => {
      trigger(pair.field1);
      trigger(pair.field2);
    });
  }, [trigger]);

  /**
   * Triggers the validation o all fields in uniqueValues 
   * 
   * @uniqueValues: UniqueValuesInfo | AllowedCombinationsInfo object
  */
  const triggerRelatedFields = useCallback((uniqueValues: UniqueValuesInfo | AllowedCombinationsInfo): void => {
    uniqueValues.fields.forEach((field) => {
      trigger(field);
    })
  }, [trigger]);

  /** 
  * Checks if any of the values given by uniqueValues have the same value
  * It will update the entire group of fields with the updated validation status
  * 
  * @uniqueValues: UniqueValuesInfo object
  * @fieldId: the field that triggered the update
  * @value: the value of fieldId
  */
  const uniqueValues = useCallback((uniqueValues: UniqueValuesInfo, fieldId: string, value: string): ValidateResult => {
    const fieldValues: string[] = uniqueValues.fields
      .map((field) => getValues(field)?.toString())
      .filter((curValue) => curValue);

    if (value && fieldValues.filter((v) => v === value).length > 1) {
      return false;
    }

    return true;

  }, [getValues]);


  /** 
   * Checks if any of the pairs given by uniquePairs have the same values
   * It will update the entire group of fields with the updated validation status
   * 
   * @uniquePairs: UniquePairsInfo object
   * @fieldId: the field that triggered the update
   * @value: the value of fieldId
  */
  const uniquePairs = useCallback((uniquePairs: UniquePairsInfo, fieldId: string, value: string): ValidateResult => {

    // store the values of the pairs for easy compare
    const fieldValues: string[] = [];
    uniquePairs.pairs.forEach((pair) => {
      const value1: string = getValues(pair.field1);
      const value2: string = getValues(pair.field2);
      if (value1 && value2) fieldValues.push(`${value1}|${value2}`);
    });

    // check if there are duplicate pairs, if so return error
    for (const pair of uniquePairs.pairs) {
      const value1: string = getValues(pair.field1);
      const value2: string = getValues(pair.field2);
      if (pair.field1 === fieldId || pair.field2 === fieldId) {
        if (value1 && value2 && fieldValues.filter((value) => value === `${value1}|${value2}`).length > 1) {
          return false;
        }
      }
    }

    return true;

  }, [getValues]);

  /**
   * Checks if the group of fields have any of the allowed values combinations 
   * as specified in allowedCombinations.values
   * It will update the entire group of fields with the updated validation status
   * 
   * @allowedCombinations: AllowedCombinationsInfo object
   * @fieldId: the field that triggered the update
   * @value: the value of fieldId
  */
  const allowedCombinations = useCallback((allowedCombinations: AllowedCombinationsInfo, fieldId: string, value: string): ValidateResult => {

    // store all field values in this group as string, sorted, separated by | for easy comparing
    // for example ['3', '2', '1'] becomes '1|2|3'
    const fieldValues: string = allowedCombinations.fields
      .map((field) => getValues(field)?.toString())
      .filter((curValue) => curValue)
      .sort()
      .join('|');

    // store all allowed values as string, sorted, separated by | for easy comparing
    // for example ['3', '2', '1'] becomes '1|2|3'
    const allowedValues: string[] = [];
    allowedCombinations.values.forEach((values) => {
      const combination = values.map((value) => value?.toString())
        .sort()
        .join('|');
      if (combination) allowedValues.push(combination);
    });

    return !isSet(value) || allowedValues.includes(fieldValues);
  }, [getValues, isSet]);

  /**
   * Makes the field required when the fieldValues match the actual form field values
   * 
   * @fieldValues: FieldValue object containing fields/values that make this field required
   * @value: the value of fieldId
  */
  const conditionalRequired = useCallback((fieldValues: FieldValue[], value: string): ValidateResult => {
    for (const field of fieldValues) {
      const fieldValue = getValues(field.field)?.toString();
      if (fieldValue && (field.value === fieldValue || field.value === 'any') && !isSet(value)) return false;
    }
    return true;
  }, [getValues, isSet]);

  /**
   * Looks for empty pairs and removes them, shifting pairs below up
   * 
   * @uniquePairs: UniquePairsInfo object
   * @fieldId: the field that triggered the update
  */
  const shiftEmptyPairs = useCallback((uniquePairs: UniquePairsInfo, fieldId: string): void => {

    // shift values
    const values: KeyValue[] = [];

    // 1. when a field value is changed to new value (not empty)
    // we only shift when both values are set
    if(getValues(fieldId)) {
      for (let i = 0; i < uniquePairs.pairs.length; i++) {
        const pair = uniquePairs.pairs[i];
        if(fieldId === pair.field1 || fieldId === pair.field2) {
          const value1 = getValues(pair.field1);
          const value2 = getValues(pair.field2);
          if(!value1 || !value2) return;
        }
      }
    }

    // 2. when the field value is changed to empty, shift
    uniquePairs.pairs.forEach((pair) => {
      const value1 = getValues(pair.field1);
      const value2 = getValues(pair.field2);
      if (value1 || value2) values.push({ field1: value1, field2: value2 });
    });

    // set new values
    for (let i = uniquePairs.pairs.length - 1; i >= 0; i--) {
      setValue(uniquePairs.pairs[i].field1, values[i]?.field1 || '', { shouldDirty: true });
      setValue(uniquePairs.pairs[i].field2, values[i]?.field2 || '', { shouldDirty: true });
    }

    // re trigger validation for new situation
    triggerRelatedPairs(uniquePairs);

  }, [setValue, getValues, triggerRelatedPairs]);

  /**
   * Looks for empty values and removes them, shifting values below up
   * 
   * @uniqueValues: UniqueValuesInfo object
   * @fieldId: the field that triggered the update
  */
  const shiftEmptyFields = useCallback((uniqueValues: UniqueValuesInfo, fieldId: string): void => {

    // shift values
    const values: string[] = [];
    uniqueValues.fields.forEach((field) => {
      const value = getValues(field);
      if (value && fieldId !== field) values.push(value);
    });

    // set new values
    for (let i = uniqueValues.fields.length - 1; i >= 0; i--) {
      setValue(uniqueValues.fields[i], values[i] || '', { shouldDirty: true });
    }

    // retrigger validation for new situation
    triggerRelatedFields(uniqueValues);

  }, [setValue, getValues, triggerRelatedFields]);

  return { isSet, uniqueValues, uniquePairs, allowedCombinations, conditionalRequired, shiftEmptyFields, shiftEmptyPairs, triggerRelatedPairs, triggerRelatedFields }
}