import { useCallback, useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule, UniqueValuesInfo } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';
import { useFormValidators } from '@src/support/hooks/useFormValidators';

type Props = {
  readOnly?: boolean,
}

const uniqueValueProducts: UniqueValuesInfo = {
  fields: [
    '51', '52', '53', '54', '55', '56', '57'
  ]
}

export default function Scenario({ readOnly = false }: Props) {

  const methods = useFormContext();
  const setValue = methods.setValue;

  const { uniqueValues, shiftEmptyFields, triggerRelatedFields } = useFormValidators();
  const { visibility, updateHandler, isVisible } = useFormVisibility();

  const {
    cache,
  } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  // shift empty values when needed
  const uniqueValueProductsChanged = useCallback((value: any, field?: string) => {
    if (field) {
      if (value === '') {
        shiftEmptyFields(uniqueValueProducts, field);
      } else {
        setValue(field, value, { shouldDirty: true });
        triggerRelatedFields(uniqueValueProducts);
      }
    }
  }, [setValue, shiftEmptyFields, triggerRelatedFields]);


  const field_49 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 49,
    description: 'Letselmechanisme',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));


  const field_50 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 50,
    description: 'Locatie',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));


  const field_51 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 51,
    description: 'Product scenario',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_52 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 52,
    description: 'Product1',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_53 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 53,
    description: 'Product2',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_54 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 54,
    description: 'Product3',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_55 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 55,
    description: 'Product4',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_56 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 56,
    description: 'Product5',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_57 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 57,
    description: 'Product6',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  return (
    <FormFieldset readOnly={readOnly} className={isVisible ? 'block mb-4' : 'd-none'}>
      <legend className="h2">Scenario</legend>
      <FormRow fieldId="49" isActive={visibility[49]} fieldRules={field_49.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_49.current.description}</span>
        <Controller
          control={methods.control}
          name="49"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_49.current.options} valueRules={field_49.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="50" isActive={visibility[50]} fieldRules={field_50.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_50.current.description}</span>
        <Controller
          control={methods.control}
          name="50"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error}
              onChange={onChange}
              options={field_50.current.options} valueRules={field_50.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="51" isActive={visibility[51]} fieldRules={field_51.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_51.current.description}</span>
        <Controller
          control={methods.control}
          name="51"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '51', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_51.current.options} valueRules={field_51.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="52" isActive={visibility[52]} fieldRules={field_52.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_52.current.description}</span>
        <Controller
          control={methods.control}
          name="52"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '52', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_52.current.options} valueRules={field_52.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="53" isActive={visibility[53]} fieldRules={field_53.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_53.current.description}</span>
        <Controller
          control={methods.control}
          name="53"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '53', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_53.current.options} valueRules={field_53.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="54" isActive={visibility[54]} fieldRules={field_54.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_54.current.description}</span>
        <Controller
          control={methods.control}
          name="54"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '54', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_54.current.options} valueRules={field_54.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="55" isActive={visibility[55]} fieldRules={field_55.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_55.current.description}</span>
        <Controller
          control={methods.control}
          name="55"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '55', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_55.current.options} valueRules={field_55.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="56" isActive={visibility[56]} fieldRules={field_56.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_56.current.description}</span>
        <Controller
          control={methods.control}
          name="56"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '56', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_56.current.options} valueRules={field_56.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="57" isActive={visibility[57]} fieldRules={field_57.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_57.current.description}</span>
        <Controller
          control={methods.control}
          name="57"
          rules={{
            validate: {
              uniqueValueProducts: (value) => uniqueValues(uniqueValueProducts, '57', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={uniqueValueProductsChanged} options={field_57.current.options} valueRules={field_57.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}