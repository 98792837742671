import localForage from 'localforage';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store/store'
import { ComboBoxItem } from '@support/components/comboBox'
import CacheAPI from './api';
import { FormField, FormFieldRule, FormValue, FormValueRule } from '@src/support/components/form/models';

localForage.config({
  driver: localForage.INDEXEDDB,
  name: 'appDB',
  version: 1.0,
  size: 4980736,
  storeName: 'cache',
  description: 'Workflow en controle cached datasets'
});

interface LisRegistrationFilter {
  statuses: ComboBoxItem[] | null,
  organizations: ComboBoxItem[] | null
  injuryCause: ComboBoxItem[] | null,
  party: ComboBoxItem[] | null,
}

interface LisUploadsFilter {
  statuses: ComboBoxItem[] | null,
  organizations: ComboBoxItem[] | null
}

interface MoveRegistrationFilter {
  status: ComboBoxItem[] | null,
  organization: ComboBoxItem[] | null,
  accidentType: ComboBoxItem[] | null,
  party: ComboBoxItem[] | null
}

interface MoveUploadsFilter {
  statuses: ComboBoxItem[] | null,
  organizations: ComboBoxItem[] | null
}

interface OrganizationsFilter {
  data: ComboBoxItem[] | null,
  regions: ComboBoxItem[] | null
}

interface CacheObj {
  version: string | null,
  lisRegistrationFilters: LisRegistrationFilter,
  lisUploadsFilters: LisUploadsFilter,
  moveRegistrationFilters: MoveRegistrationFilter,
  moveUploadsFilters: MoveUploadsFilter,
  organizationsFilters: OrganizationsFilter,
  lisFormFields: FormField[],
  lisFormValues: FormValue[],
  lisFormFieldRules: FormFieldRule[],
  lisFormValueRules: FormValueRule[],
  formFields: FormField[],
  formValues: FormValue[],
  formFieldRules: FormFieldRule[],
  formValueRules: FormValueRule[],
}

export interface CacheState {
  status: 'idle' | 'loading' | 'failed';
  cache: CacheObj
}

const initialState: CacheState = {
  status: 'loading',
  cache: {
    version: null,
    lisRegistrationFilters: {
      statuses: [],
      organizations: [],
      injuryCause: [],	
      party: []
    },
    lisUploadsFilters: {
      statuses: [],
      organizations: []
    },
    moveRegistrationFilters: {
      status: [],
      organization: [],
      accidentType: [],
      party: []
    },
    moveUploadsFilters: {
      statuses: [],
      organizations: []
    },
    organizationsFilters: {
      data: [],
      regions: []
    },
    lisFormFields: [],
    lisFormValues: [],
    lisFormFieldRules: [],
    lisFormValueRules: [],   
    formFields: [],
    formValues: [],
    formFieldRules: [],
    formValueRules: []
  }
};

export const initCacheAsync = createAsyncThunk<any, void, { state: RootState }>(
  'cache/initCacheAsync',
  async (_, thunkAPI) => {
    try {

      // get the cache version from the API
      const result = await CacheAPI.version();

      // get the local version from the indexeddb
      const localVersion: any = await localForage.getItem('version');

      // If the API cache version is newer, reload all cached data and
      // store it in the indexeddb
      if (result.version !== localVersion) {

        try {

          const [
            lisRegistrationFiltersResult,
            lisUploadsFiltersResult,
            moveRegistrationFiltersResult,
            moveUploadsFiltersResult,
            organizationsFiltersResult,
            lisFormFieldsResult,
            lisFormValuesResult,
            lisFormFieldRulesResult,
            lisFormValueRulesResult,
            formFieldsResult,
            formValuesResult,
            formFieldRulesResult,
            formValueRulesResult,
          ] = await Promise.all([
            CacheAPI.lisRegistrationFilters(),
            CacheAPI.lisUploadsFilters(),
            CacheAPI.moveRegistrationFilters(),
            CacheAPI.moveUploadsFilters(),
            CacheAPI.organizationsFilters(),
            CacheAPI.lisFormFields(),
            CacheAPI.lisFormValues(),
            CacheAPI.lisFormFieldRules(),
            CacheAPI.lisFormValueRules(),
            CacheAPI.formFields(),
            CacheAPI.formValues(),
            CacheAPI.formFieldRules(),
            CacheAPI.formValueRules(),
          ]);

          await localForage.setItem('version', result.version);
          await localForage.setItem('lisRegistrationFilters', lisRegistrationFiltersResult);
          await localForage.setItem('lisUploadsFilters', lisUploadsFiltersResult);
          await localForage.setItem('moveRegistrationFilters', moveRegistrationFiltersResult);
          await localForage.setItem('moveUploadsFilters', moveUploadsFiltersResult);
          await localForage.setItem('organizationsFilters', organizationsFiltersResult);
          await localForage.setItem('lisFormFields', lisFormFieldsResult);
          await localForage.setItem('lisFormValues', lisFormValuesResult);
          await localForage.setItem('lisFormFieldRules', lisFormFieldRulesResult);
          await localForage.setItem('lisFormValueRules', lisFormValueRulesResult);
          await localForage.setItem('formFields', formFieldsResult);
          await localForage.setItem('formValues', formValuesResult);
          await localForage.setItem('formFieldRules', formFieldRulesResult);
          await localForage.setItem('formValueRules', formValueRulesResult);

        } catch (error: any) {
          return thunkAPI.rejectWithValue({ error: error.response.data })
        }
      }

      // store the cached data from the indexeddb a new cache object 
      const newCache: CacheObj = {
        ...initialState.cache
      }

      newCache.version = await localForage.getItem('version');
      newCache.lisRegistrationFilters = await localForage.getItem('lisRegistrationFilters') as LisRegistrationFilter;
      newCache.lisUploadsFilters = await localForage.getItem('lisUploadsFilters') as LisUploadsFilter;
      newCache.moveRegistrationFilters = await localForage.getItem('moveRegistrationFilters') as MoveRegistrationFilter;
      newCache.moveUploadsFilters = await localForage.getItem('moveUploadsFilters') as MoveUploadsFilter;
      newCache.organizationsFilters = await localForage.getItem('organizationsFilters') as OrganizationsFilter;
      newCache.lisFormFields = await localForage.getItem('lisFormFields') as FormField[];
      newCache.lisFormValues = await localForage.getItem('lisFormValues') as FormValue[];
      newCache.lisFormFieldRules = await localForage.getItem('lisFormFieldRules') as FormFieldRule[];
      newCache.lisFormValueRules = await localForage.getItem('lisFormValueRules') as FormValueRule[];
      newCache.formFields = await localForage.getItem('formFields') as FormField[];
      newCache.formValues = await localForage.getItem('formValues') as FormValue[];
      newCache.formFieldRules = await localForage.getItem('formFieldRules') as FormFieldRule[];
      newCache.formValueRules = await localForage.getItem('formValueRules') as FormValueRule[];

      return newCache;

    } catch (error: any) {
      return thunkAPI.rejectWithValue({ error: error.response.data })
    }
  }
)

export const cacheSlice = createSlice({
  name: 'cache',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // --------------------------------------------------------------------------
      // checkVersionAsync
      .addCase(initCacheAsync.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(initCacheAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(initCacheAsync.fulfilled, (state, action) => {
        state.cache = action.payload as CacheObj;
        state.status = 'idle';
      })
  },
});

export default cacheSlice.reducer;
