import { useCallback, useRef } from 'react';
import { useAppSelector } from '@src/app/store/hooks';
import { Controller, useFormContext } from 'react-hook-form';

// support
import FormFieldset from '@src/support/components/form/FormFieldset';
import FormRow from '@src/support/components/form/FormRow';
import TextareaField from '@src/support/components/textareaField';
import { FormField, FormFieldRule, FormFieldSelect, FormValue, FormValueRule, AllowedCombinationsInfo } from '@src/support/components/form/models';
import { getFormSelect } from '@src/support/helpers/formHelper';
import { useFormVisibility } from '@src/support/hooks/useFormVisibility';
import ComboBoxDynamic from '@src/support/components/comboBoxDynamic';
import { useFormValidators } from '@src/support/hooks/useFormValidators';

type Props = {
  readOnly?: boolean,
}

const allowedCombinationsFacts: AllowedCombinationsInfo = {
  fields: [
    '19', '20', '21', '22'
  ],
  values: [
    ['6'], // prive
    ['4'], // verkeer 
    ['7'], // overig vervoer
    ['5'], // zelf mutulatie
    ['3'], // geweld
    ['2'], // sport
    ['1'], // arbeid

    ['2', '4'], // sport | verkeer
    ['2', '7'], // sport | overig vervoer
    ['2', '3'], // sport | geweld 

    ['1', '4'], // arbeid | verkeer
    ['1', '7'], // arbeid | overig vervoer
    ['1', '3'], // arbeid | geweld
    ['1', '2'], // arbeid | sport

    ['1', '2', '4'], // arbeid | sport | verkeer
    ['1', '2', '7'], // arbeid | sport | overig vervoer 
    ['1', '2', '3'], // arbeid | sport | geweld 
  ]
}

export default function AccidentFacts({ readOnly = false }: Props) {

  const methods = useFormContext();
  const setValue = methods.setValue;
  const getValue = methods.getValues;
  const { visibility, updateHandler } = useFormVisibility();

  const { isSet, allowedCombinations, shiftEmptyFields, triggerRelatedFields } = useFormValidators();
  const { cache } = useAppSelector(state => state.cache)

  const formFields = useRef<FormField[]>(cache.lisFormFields || []);
  const formFieldRules = useRef<FormFieldRule[]>(cache.lisFormFieldRules || []);
  const formValues = useRef<FormValue[]>(cache.lisFormValues || []);
  const formValueRules = useRef<FormValueRule[]>(cache.lisFormValueRules || []);

  // shift empty values when needed
  const allowedCombinationsFactsChanged = useCallback((value: any, field?: string) => {
    if (field) {
      if (value === '') {
        shiftEmptyFields(allowedCombinationsFacts, field);
      } else {
        setValue(field, value, { shouldDirty: true });
        triggerRelatedFields(allowedCombinationsFacts);
      }
    }
  }, [setValue, shiftEmptyFields, triggerRelatedFields]);

  const field_17 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 17,
    description: 'Reden bezoek',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_18 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 18,
    description: 'Type ziekte/aandoening',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_19 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 19,
    description: 'Oorzaak letsel 1',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current,
  }));

  const field_20 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 20,
    description: 'Oorzaak letsel 2',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_21 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 21,
    description: 'Oorzaak letsel 3',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  const field_22 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 22,
    description: 'Oorzaak letsel 4',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  // force MOVE fields hidden when hospital is not in LIS & MOVE
  // field 114 is given by the back-end to indicate if the hospital is in both LIS & MOVE
  // if not, hide the MOVE fields by removing all visibility rules for the MOVE fields
  // and replace them with a single rule that hides them
  // 
  // this implementation speeds up the rendering of the form by removing a lot of unnecessary checks
  const isLisMoveHospital = getValue('114') === true;

  const field_62 = useRef<FormFieldSelect>(getFormSelect({ 
    forceHidden: !isLisMoveHospital,
    fieldId: 62, 
    description: 'Provincie', 
    formFields: formFields.current, 
    fieldRules: formFieldRules.current,
    values: formValues.current, 
    valueRules: formValueRules.current 
  }));

  const field_36 = useRef<FormFieldSelect>(getFormSelect({
    fieldId: 36,
    description: 'Type overig vervoersongeval',
    formFields: formFields.current,
    fieldRules: formFieldRules.current,
    values: formValues.current,
    valueRules: formValueRules.current
  }));

  return (
    <FormFieldset readOnly={readOnly} className={'block mb-4'}>
      <legend className="h2">Toedracht</legend>
      <FormRow fieldId="16" isActive={true} classNames={{ leftColumn: 'col-12', rightColumn: 'd-none' }}>
        <Controller
          control={methods.control}
          name="16"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <TextareaField name={name} value={value || ''} error={error} onChange={onChange} />
          )}
        />
        <></>
      </FormRow>

      <FormRow fieldId="17" isActive={true} fieldRules={field_17.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_17.current.description}</span>
        <Controller
          control={methods.control}
          name="17"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_17.current.options} valueRules={field_17.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="18" isActive={visibility[18]} fieldRules={field_18.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_18.current.description}</span>
        <Controller
          control={methods.control}
          name="18"
          rules={{ required: false }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error} onChange={onChange} options={field_18.current.options} valueRules={field_18.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="19" isActive={visibility[19]} fieldRules={field_19.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_19.current.description}</span>
        <Controller
          control={methods.control}
          name="19"
          rules={{
            validate: {
              allowedCombinationsFacts: (value) => allowedCombinations(allowedCombinationsFacts, '19', value),
              required: (value) => isSet(value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error}
              onChange={allowedCombinationsFactsChanged} options={field_19.current.options} valueRules={field_19.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="20" isActive={visibility[20]} fieldRules={field_20.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_20.current.description}</span>
        <Controller
          control={methods.control}
          name="20"
          rules={{
            validate: {
              allowedCombinationsFacts: (value) => allowedCombinations(allowedCombinationsFacts, '20', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={allowedCombinationsFactsChanged} options={field_20.current.options} valueRules={field_20.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="21" isActive={visibility[21]} fieldRules={field_21.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_21.current.description}</span>
        <Controller
          control={methods.control}
          name="21"
          rules={{
            validate: {
              allowedCombinationsFacts: (value) => allowedCombinations(allowedCombinationsFacts, '21', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={allowedCombinationsFactsChanged} options={field_21.current.options} valueRules={field_21.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="22" isActive={visibility[22]} fieldRules={field_22.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_22.current.description}</span>
        <Controller
          control={methods.control}
          name="22"
          rules={{
            validate: {
              allowedCombinationsFacts: (value) => allowedCombinations(allowedCombinationsFacts, '22', value)
            }
          }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} hasResetOption={true} error={error}
              onChange={allowedCombinationsFactsChanged} options={field_22.current.options} valueRules={field_22.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="36" isActive={visibility[36]} fieldRules={field_36.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_36.current.description}</span>
        <Controller
          control={methods.control}
          name="36"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_36.current.options} valueRules={field_36.current.valueRules} />
          )}
        />;
      </FormRow>

      <FormRow fieldId="62" isActive={visibility[62]} fieldRules={field_62.current.fieldRules} onFormRowEvent={updateHandler}>
        <span>{field_62.current.description}</span>
        <Controller
          control={methods.control}
          name="62"
          rules={{ required: true }}
          render={({ field: { name, value, onChange }, fieldState: { error } }) => (
            <ComboBoxDynamic name={name} value={value} error={error} onChange={onChange} options={field_62.current.options} valueRules={field_62.current.valueRules} />
          )}
        />;
      </FormRow>
    </FormFieldset>
  )
}